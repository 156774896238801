<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="blackKits"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Daily Excerpt
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
const formTitle = 'Daily Excerpt'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      blackKits: [
        /*
        {
          icon: 'mdi-medical-bag',
          path: '/management/prihatin_stat',
          title: 'Prihatin',
          desc: 'Daily activation & update plan report.',
        },
        */
        {
          icon: 'mdi-clipboard-account',
          path: '/management/daily_activation',
          title: 'Daily Activation',
          desc: 'Daily activation report.',
        },
        {
          icon: 'mdi-map-marker-multiple-outline',
          path: '/management/activations_by_state',
          title: 'Activations & Recruitments by State',
          desc: 'Daily activations & recruitments by state.',
        },
        {
          icon: 'mdi-account-plus',
          path: '/management/daily_recruitment',
          title: 'Daily Recruitment',
          desc: 'Daily recruitment report.',
        },
        {
          icon: 'mdi-soccer',
          path: '/management/daily_fc_activation',
          title: 'Daily FC Activation',
          desc: 'Daily FC activation report.',
        },
        {
          icon: 'mdi-soccer-field',
          path: '/management/daily_fc_distribution',
          title: 'Daily FC Plan Distribution',
          desc: 'Daily FC plan distribution report.',
        },
        {
          icon: 'mdi-chart-bar',
          path: '/management/daily_prepaid',
          title: 'Daily Prepaid Activation',
          desc: 'Daily Prepaid Activation report.',
        },
        /*
        {
          icon: 'mdi-pin-outline',
          path: '/management/black_pin_usage',
          title: 'Black Pin Usage',
          desc: 'Daily Black Pin usage.',
        },
        */
      ],
      clearance: '',
      code: 0,
      height: 'auto',
    }
  },
}
</script>
